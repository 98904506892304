<template>
    <picture :class="classObj">
        <source :srcset="webpName" type="image/webp" />
        <source :srcset="getImagePath" type="image/png" />
        <v-lazy-img :src="getImagePath" :class="classObj" :alt="alt" loading="lazy"> </v-lazy-img>
    </picture>
</template>

<script>
import VLazyImage from "v-lazy-image";

export default {
    name: "img-webp",
    props: ["src", "alt", "classs"],
    components: {
        "v-lazy-img": VLazyImage,
    },
    computed: {
        webpName() {
            return `${this.getImgUrl(this.src + ".webp")}`;
        },
        extention() {
            let explode = this.src.split(".");
            return explode[explode.length - 1];
        },
        source() {
            return this.src;
        },
        getImagePath() {
            return `${this.getImgUrl(this.src)}`;
        },
        classObj() {
            let classes = this.classs ? this.classs.split(" ") : [];
            return classes;
        },
    },
    methods: {
        getImgUrl(uri) {
            let con = require("../../assets" + uri);
            return con;
        },
    },
};
</script>
<style lang="scss" scoped>
.hero-img {
    width: 100%;
    height: auto;
}
</style>
